<template>
  <ais-instant-search :search-client="searchClient" :index-name="primaryIndexName" :search-function="searchFunction">
    <debounced-search-box :delay="debounceDelay" autofocus placeholder="Search Here" />
    <ais-state-results class="nav-search-results" :hits-per-page="5">
      <template v-slot="{ state: { query }, results: { hits } }">
        <div class="empty-results" v-show="!hits.length || !query.length">
          <span>Search above for pages, faculty, articles, or events to have results automatically displayed.</span>
        </div>
        <ais-hits :transform-items="transformItems" v-show="hits.length && query.length">
          <template v-slot:item="{ item }">
            <a :href="item.permalink">
              <span v-html="item._highlightResult.post_title.value.slice(0,50)"></span>
              <span v-html="item.post_type_label"></span>
            </a>
          </template>
        </ais-hits>
    </template>
    </ais-state-results>
  </ais-instant-search>
</template>

<style lang="scss">
  .site-nav-second .nav-container input[type="search"].debounced-search-box-input {
    border: 0;
    background: none;
    border-bottom: 2px solid black;
    outline: none;
    box-shadow: none;
    padding-left: 0;
    width: 350px;
  }

  .nav-search-results.ais-StateResults {
    @apply bg-off-white text-black border-brand;
    border-bottom-width: 8px;
    position: absolute;
    top: 43px;
    right: -1rem;
    width: 100vw;
    height: 250px;
    @screen sm {
      top: 51px;
      right: 0;
      height: 500px;
      min-width: 400px;
      max-width: 975px;
      width: 85vw;
    }
    z-index: 25;
    overflow: hidden;

    .ais-Hits-item {
      padding: 0;
      margin: 0;
      height: auto;
      line-height: auto;

      a {
        @apply px-4 py-4 text-lg block flex justify-between;
        border-bottom: 1px solid #e2e2e2;

        &:hover {
          background-color: rgba(0,0,0,0.03);
        }
      }
    }
  }

  .empty-results {
    @apply p-8 text-gray-400 text-3xl font-light;
  }
</style>

<script>
// import algoliasearch from 'algoliasearch/lite';
import {
  AisInstantSearch,
  AisStateResults,
  AisHits,
} from 'vue-instantsearch/vue3/es';
import DebouncedSearchBox from './components/DebouncedSearchBox.vue'
// import 'instantsearch.css/themes/satellite-min.css';

// const algoliaClient = algoliasearch(
//   'O5RPD0U2FE',
//   '94ee45b01d6cf73471a2e166dfea3af7'
// );

export default {
  name: 'SearchInterface',
  inject: ['algoliaClient', 'searchEl'],
  components: {
    AisInstantSearch,
    DebouncedSearchBox,
    AisStateResults,
    AisHits,
  },
  unmounted() {
  },
  mounted() {
    let self = this
    let searchLauncherEl = document.getElementById('nav-search-launcher')
    searchLauncherEl.addEventListener('click', function() {
      self.activateSearch()
    })
  },

  data() {

    // Set up a specialized debounced searchClient that wraps the
    // globally provided algoliaClient from main.js
    const self = this
    const searchClient = {
      ...self.algoliaClient,
      search(requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        }

        return self.algoliaClient.search(requests);
      },
    }

    return {
      searchEl: this.searchEl,
      debounceDelay: this.searchEl.dataset.debounceDelay || 200,
      primaryIndexName: (this.searchEl.dataset.indexPrefix || '') + 'searchable_posts',
      searchClient,
      searchingActive: false,
    };
  },

  methods: {
    /* or, combined with results */
    transformItems(items) {
      // console.log(results)
      return items
      // return items.map((item, index) => ({
      //   ...item,
      //   position: { index, page: results.page },
      // }));
    },

    searchFunction: function(helper) {     
      helper.search()         
      // if (helper.state.query) {
      //   helper.search()
      // } else {
      //   console.log(helper.state)
      // }
    },

    activateSearch() {
      let self = this
      let siteNavSecondaryEl = document.getElementsByClassName("site-nav-second")[0];
      siteNavSecondaryEl.classList.add("searching");

      let searchBoxEl = document.getElementsByClassName("debounced-search-box-input")[0];
      searchBoxEl.focus()
      // searchBoxEl.addEventListener('blur', this.deactivateSearch)

      setTimeout(function(){
        document.addEventListener("click", self.checkClickInside)
        document.body.classList.add('showOverlaySearch');
      }, 100);

      this.searchingActive = true
      this.emitter.emit("searching-active", this.searchingActive);

    },

    checkClickInside(e) {
      let searchBoxEl = document.getElementsByClassName("debounced-search-box-input")[0];
      const isClickInside = searchBoxEl.contains(e.target);

      if (!isClickInside) {
        this.deactivateSearch();
      }
    },

    deactivateSearch() {
      document.removeEventListener("click", this.checkClickInside)

      let siteNavSecondaryEl = document.getElementsByClassName("site-nav-second")[0];
      siteNavSecondaryEl.classList.remove("searching");

      let searchBoxEl = document.getElementsByClassName("debounced-search-box-input")[0];
      searchBoxEl.removeEventListener("blur", this.deactivateSearch);

      document.body.classList.remove('showOverlaySearch');
      this.searchingActive = false
      this.emitter.emit("searching-active", this.searchingActive);
    }
  },

  computed: {
  },

}
</script>
