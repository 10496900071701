<template>
  <ais-instant-search class="container" :search-client="facultySearchClient" :index-name="primaryIndexName" :search-function="searchFunction">
    <debounced-search-box :delay="debounceDelay" placeholder="Search Faculty Here" />
    <ais-state-results class=" faculty-search-results" :hits-per-page="5">
      <template v-slot="{ results: { hits } }">
        <div class="empty-results" v-show="!hits.length">
          <span>Search above for faculty and staff members.</span>
        </div>
        <ais-hits class="" :transform-items="transformItems" v-show="hits.length">
          <template v-slot:item="{ item }">
            <a class="" :href="item.permalink">
                <img
                    class="mb-4 faculty-directory-photo"
                    :src="item.photo_url.replace('http', 'https')"
                /><br />
                <span class="text-brand hover-underline-brand" v-html="item._highlightResult.post_title.value"></span><br />
                <span class="text-gray-600" v-html="item.subtitle"></span>
            </a>
          </template>
        </ais-hits>
    </template>
    </ais-state-results>
  </ais-instant-search>
</template>

<style lang="scss">
  .site-nav-second .nav-container input[type="search"].debounced-search-box-input {
    border: 0;
    background: none;
    border-bottom: 2px solid black;
    outline: none;
    box-shadow: none;
    padding-left: 0;
    width: 350px;
  }

  .ais-StateResults.faculty-search-results .ais-Hits-list {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4;

    .ais-Hits-item {
      @apply text-center block bg-off-white p-8;

      .faculty-directory-photo {
        @apply bg-slate-600;
        display: inline-block;
        border-radius: 50%;
        height: 160px;
        width: 160px;
        object-fit: cover;
        object-position: 0 5%;
        border: 1px solid #8a8a8a;
      }
    }
  }



  // .faculty-search-results.ais-StateResults {
  //   @apply bg-off-white text-black border-brand;
  //   border-bottom-width: 8px;
  //   position: absolute;
  //   top: 54px;
  //   right: -1rem;
  //   width: 100vw;
  //   height: 250px;
  //   @screen sm {
  //     top: 51px;
  //     right: 0;
  //     height: 500px;
  //     min-width: 400px;
  //     max-width: 975px;
  //     width: 85vw;
  //   }
  //   z-index: 25;
  //   overflow: hidden;

  //   .ais-Hits-item {
  //     padding: 0;
  //     margin: 0;
  //     height: auto;
  //     line-height: auto;

  //     a {
  //       @apply px-4 py-4 text-lg block flex justify-between;
  //       border-bottom: 1px solid #e2e2e2;

  //       &:hover {
  //         background-color: rgba(0,0,0,0.03);
  //       }
  //     }
  //   }
  // }

  .empty-results {
    @apply p-8 text-gray-400 text-3xl font-light;
  }
</style>

<script>

import {
  AisInstantSearch,
  AisStateResults,
  AisHits,
} from 'vue-instantsearch/vue3/es';
import DebouncedSearchBox from './components/DebouncedSearchBox.vue'
// import 'instantsearch.css/themes/satellite-min.css';

// const facultySearchElId = 'kircher-faculty-search';
// const facultySearchEl = document.getElementById(facultySearchElId)

export default {
  name: 'FacultySearchInterface',
  inject: ['algoliaClient', 'facultySearchEl'],
  components: {
    AisInstantSearch,
    DebouncedSearchBox,
    AisStateResults,
    AisHits,
  },
  unmounted() {
  },
  mounted() {
    // let self = this
    // let searchLauncherEl = document.getElementById('nav-search-launcher')
    // searchLauncherEl.addEventListener('click', function() {
    //   self.activateSearch()
    // })
  },

  data() {

    // Set up a specialized debounced searchClient that wraps the
    // globally provided algoliaClient from main.js
    const self = this
    const facultySearchClient = {
      ...self.algoliaClient,
      search(requests) {
        requests[0].params.filters = 'post_type:' + self.postType
        console.log(requests)
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        }

        return self.algoliaClient.search(requests);
      },
    }
    return {
      facultySearchEl: this.facultySearchEl,
      postType: this.facultySearchEl.dataset.postType || 'faculty_member',
      debounceDelay: this.facultySearchEl.dataset.debounceDelay || 200,
      primaryIndexName: (this.facultySearchEl.dataset.indexPrefix || '') + 'searchable_posts',
      facultySearchClient,
      searchingActive: false,
    };
  },

  methods: {
    /* or, combined with results */
    transformItems(items) {
      // console.log(results)
      return items
      // return items.map((item, index) => ({
      //   ...item,
      //   position: { index, page: results.page },
      // }));
    },

    searchFunction: function(helper) {     
      helper.search()         
      // if (helper.state.query) {
      //   helper.search()
      // } else {
      //   console.log(helper.state)
      // }
    },

    // activateSearch() {
    //   let self = this
    //   let siteNavSecondaryEl = document.getElementsByClassName("site-nav-second")[0];
    //   siteNavSecondaryEl.classList.add("searching");

    //   let searchBoxEl = document.getElementsByClassName("debounced-search-box-input")[0];
    //   searchBoxEl.focus()
    //   // searchBoxEl.addEventListener('blur', this.deactivateSearch)

    //   setTimeout(function(){
    //     document.addEventListener("click", self.checkClickInside)
    //     document.body.classList.add('showOverlaySearch');
    //   }, 100);

    //   this.searchingActive = true
    //   // this.emitter.emit("searching-active", this.searchingActive);

    // },

    // checkClickInside(e) {
    //   let searchBoxEl = document.getElementsByClassName("debounced-search-box-input")[0];
    //   const isClickInside = searchBoxEl.contains(e.target);

    //   if (!isClickInside) {
    //     this.deactivateSearch();
    //   }
    // },

    // deactivateSearch() {
    //   document.removeEventListener("click", this.checkClickInside)

    //   let siteNavSecondaryEl = document.getElementsByClassName("site-nav-second")[0];
    //   siteNavSecondaryEl.classList.remove("searching");

    //   let searchBoxEl = document.getElementsByClassName("debounced-search-box-input")[0];
    //   searchBoxEl.removeEventListener("blur", this.deactivateSearch);

    //   document.body.classList.remove('showOverlaySearch');
    //   this.searchingActive = false
    //   // this.emitter.emit("searching-active", this.searchingActive);
    // }
  },

  computed: {
  },

}
</script>
