<template>
  <div class="kircher-expanded-menu">
    <a :href="targetUrl" class="left-side">
      <div class="menu-photo picture-img-wrapper">
        <picture>
          <source :srcset="thumbnailWebp" type="image/webp">
          <img :src="thumbnail" :alt="label + ' Nav Image'" class="picture-img-fill">
        </picture>
        <span class="menu-photo-description" v-text="description"></span>
      </div>
      <div class="menu-button">
        <span class="menu-button-label" v-text="title || label"></span>
        <span class="menu-button-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </span>
      </div>
    </a>
    <div class="right-side">
      <ul>
        <li class="menu-child-item">
          <a :href="targetUrl" v-text="label"></a>
        </li>
        <li
          v-for="(navChild, index) in navChildren"
          :key="index"
          class="menu-child-item"
        >
          <a :href="navChild.url">{{ navChild.post_title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">

$menu-spacer-height: 8px;
$menu-button-height: 50px;
$menu-button-px: 2rem;

$expanded-menu-width: 85vw;
$expanded-menu-min-width: 400px;
$expanded-menu-max-width: 975px;

$right-side-gap: 3rem;

.kircher-expanded-menu,
.menu-spacer {
  position: absolute;
  right: 0;
  min-width: $expanded-menu-min-width;
  max-width: $expanded-menu-max-width;
  width: $expanded-menu-width;
  z-index: 25;
}

.menu-spacer {
  height: $menu-spacer-height;
}

.kircher-expanded-menu {
  @apply bg-off-white;
  transform: translate3d(0,0,0); /* Safari Fix */
  display: flex;
  top: $menu-spacer-height;
  height: 400px;
  @screen lg {
    height: 500px;
  }
  color: #fff;
  padding: 0;
  box-sizing: border-box;

  .left-side {
    @apply bg-brand text-brand hover:text-white;
    position: relative;
    flex-shrink: 0;
    width: 320px;
    max-width: 320px;
    @screen lg {
      width: 420px;
      max-width: 420px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: $menu-button-height;
      height: 60%;
      left: 0;
      right: 0;
      background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6));
      z-index: 7;
    }

    .menu-photo {
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: $menu-button-height;

      .menu-photo-description {
        @apply font-headline tracking-wide;
        position: absolute;
        bottom: 1.5rem;
        left: $menu-button-px;
        right: $menu-button-px;
        font-size: 2.35rem;
        @screen lg {
          font-size: 3rem;
        }
        font-weight: 600;
        line-height: 0.95;
        z-index: 8;
      }
    }

    .menu-button {
      @apply font-sans bg-alt-blue;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: $menu-button-height;
      padding-left: $menu-button-px;
      padding-right: $menu-button-px;
      transition: all 0.3s ease;

      & > * {
        display: block;
      }

      .menu-button-label {
        @apply uppercase;
        flex-grow: 1;
      }

      .menu-button-icon > svg {
        transition: all 0.3s ease;
        transform: translateX(-5px);
        height: 22px;
        width: 22px;
      }
    }

    &:hover .menu-button {
      background: none;
      color: rgba(255, 255, 255, 1);
      opacity: 1;

      .menu-button-icon > svg {
        transform: translateX(0px);
      }
    }
  }

  .right-side {
    flex-grow: 1;
    padding: ($right-side-gap / 2);
    padding-top: ($right-side-gap - 0.5rem) / 2;
    @screen lg {
      padding: $right-side-gap;
      padding-top: $right-side-gap - 0.5rem;
    }

    ul {
      position: relative;
      list-style: none;
      columns: 2;
      column-gap: $right-side-gap;

      &:before {
        @apply bg-off-white;
        content:'';
        width:100%;
        height: 3px;
        position: absolute;
        top:0;
        left:0;
      } 

      li {
        display: inline-block;
        width: 100%;
        padding: 0.75em 0;
        border-top: 1px solid #bababf;

        &:first-child {
          margin-top: 1px;
          border-top: 0;
        }

        a {
        }
      }

      .menu-child-item {
        @apply text-lg hover:underline decoration-brand decoration-2 underline-offset-2 font-sans uppercase tracking-wide;
        line-height: 1.1;
        @screen lg {
          line-height: 1.2;
        }

        & > a {
          @apply text-brand;
        }
      }
    }
  }
}

</style>

<script>
export default {
  name: 'ExpandedMenu',

  props: {
    slug: String,
    label: String,
    description: String,
    title: String,
    targetUrl: String,
    childrenJson: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: String,
      default: 'https://live.staticflickr.com/65535/51969674801_8cd7c59f38_k_d.jpg',
      required: false,
    },
    thumbnailWebp: {
      type: String,
      default: 'https://live.staticflickr.com/65535/51969674801_8cd7c59f38_k_d.jpg',
      required: false,
    },
  },

  data() {
    return {
      navChildren: null,
    }
  },

  mounted() {
    if(this.childrenJson) {
      this.navChildren = JSON.parse(this.childrenJson)
    }
  },
}
</script>

