import { createApp } from 'vue'
import DesktopNav from './DesktopNav.vue'
import SearchApp from './Search.vue'
import FacultySearchApp from './FacultySearch.vue'
import algoliasearch from 'algoliasearch/lite'
import mitt from 'mitt';

/* Event Bus */
const emitter = mitt();

/* Tailwind CSS */
import './css/input.css';

const navMountID = '#kircher-nav'
const navMountEl = document.querySelector(navMountID);
const app = createApp(DesktopNav, { ...navMountEl.dataset })
	app.config.globalProperties.emitter = emitter;
	app.mount(navMountID)

const searchMountID = '#kircher-search'
const searchMountEl = document.querySelector(searchMountID);
if(searchMountEl) {
	const search = createApp(SearchApp, { ...searchMountEl.dataset })
		search.config.globalProperties.emitter = emitter
		search.provide('algoliaClient', algoliasearch(
			searchMountEl.dataset.applicationId,
			searchMountEl.dataset.searchApiKey
		))
		search.provide('searchEl', searchMountEl)
		search.mount(searchMountID)
}

const facultySearchMountID = '#kircher-faculty-search'
const facultySearchMountEl = document.querySelector(facultySearchMountID);
if(facultySearchMountEl) {
	const facultySearch = createApp(FacultySearchApp, { ...facultySearchMountEl.dataset })
		facultySearch.config.globalProperties.emitter = emitter
		facultySearch.provide('algoliaClient', algoliasearch(
			facultySearchMountEl.dataset.applicationId,
			facultySearchMountEl.dataset.searchApiKey
		))
		facultySearch.provide('facultySearchEl', facultySearchMountEl)
		facultySearch.mount(facultySearchMountID)
}