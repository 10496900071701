<template>
  <div id="kircher-nav-menu" :style="'top:' + (navYOffset) + 'px; right: 0px;'">
    <div class="menu-spacer"></div>
    <Transition
      v-for="navItemEl in navItemEls"
      :key="navItemEl.slug"
      name="slide-fade"
    >
      <expanded-menu
        :slug="navItemEl.dataset.slug"
        :label="navItemEl.dataset.label"
        :description="navItemEl.dataset.description"
        :thumbnail="navItemEl.dataset.thumbnail"
        :thumbnailWebp="navItemEl.dataset.thumbnailWebp"
        :title="navItemEl.dataset.title"
        :targetUrl="navItemEl.dataset.targetUrl"
        :childrenJson="navItemEl.dataset.childrenJson"
        v-show="currentNavItem && navItemEl.dataset.slug == currentNavItem.dataset.slug"
      >
      </expanded-menu>
    </Transition>
  </div>
</template>

<style>
  #kircher-nav-menu {
    position: absolute;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease-out 0.2s;
  }

  .slide-fade-enter-to,
  .slide-fade-leave-from {
    clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    clip-path: polygon(0% 0%,100% 0%,100% 0%,0% 0%);
    opacity: 0;
  }

  body:not(.scroll-down)::after {
    content: "";
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    /*background: rgba(0,29,61,.8);*/
    background: rgba(255,255,255,.6);
    transition: all .3s ease .25s;
    pointer-events: none;
    z-index: 6;
  }

  body.has-nav-hero.has-nav-hero-dark:not(.scroll-down):not(.scroll-up)::after {
    background: rgba(0,0,0,0.6);
  }

  body.showOverlayNav::after,
  body.showOverlaySearch::after {
    opacity: 1;
  }
</style>

<script>
import ExpandedMenu from './components/ExpandedMenu'

function setupScrollWatching(siteNavEl, context) {
  const body = document.body;
  // const siteNav = document.getElementsByClassName('site-nav')[0];
  // const menu = document.querySelector(".page-header .menu");
  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  const atTop = "at-top";
  const at0 = "at-0";
  const navTransition = "nav-transition";
  const topThreshold = siteNavEl.offsetHeight;
  let lastScroll = 0;
  // const startingScroll = window.pageYOffset;

  // if(startingScroll >= topThreshold) {
  //     body.classList.remove(atTop);
  // }

  var reveals = document.querySelectorAll(".reveal");
  var revealsLate = document.querySelectorAll(".reveal-late");

  window.addEventListener("scroll", () => {
      for (var r = 0; r < reveals.length; r++) {
        if (reveals[r].getBoundingClientRect().top < window.innerHeight - 150) {
          reveals[r].classList.add("active");
        }
      }

      for (var rl = 0; rl < revealsLate.length; rl++) {
        if (revealsLate[rl].getBoundingClientRect().top < window.innerHeight - 350) {
          revealsLate[rl].classList.add("active");
        }
      }

      const currentScroll = window.pageYOffset;
      if(currentScroll <= 0) {
        body.classList.add(at0);
        body.classList.remove(scrollUp);
      } else if(body.classList.contains(at0)) {
        body.classList.remove(at0);
      }

      if (currentScroll <= (topThreshold*2)) {
          body.classList.add(atTop);
          body.classList.remove(navTransition);
          return;
      }
   
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
          // down
          body.classList.remove(scrollUp);
          body.classList.remove(atTop);
          body.classList.add(scrollDown);
          setTimeout(function() {
            context.clearCurrentNavItem()
            body.classList.add(navTransition)
          }, 100);
          // lottiePlayer.play();
      } else if (
          currentScroll < lastScroll &&
          body.classList.contains(scrollDown)
      ) {
          // up
          body.classList.remove(scrollDown);
          body.classList.add(scrollUp);
          // lottiePlayer.stop();
      }
      lastScroll = currentScroll;
  });
}

export default {
  name: 'DesktopNav',
  components: {
    'expanded-menu':ExpandedMenu,
  },
  unmounted() {
    window.removeEventListener("resize", this.setNavOffsets);
  },
  mounted() {
    let self = this
    this.navItemEls = document.getElementsByClassName('kircher-primary-nav-item')
    window.addEventListener("resize", this.setNavOffsets);
    this.setNavOffsets()

    let navAreaEl = document.getElementsByClassName('site-nav-second')[0]
    navAreaEl.addEventListener('mouseleave', function() {
      self.clearCurrentNavItem()
    })

    Array.from(this.navItemEls).forEach(function(itemEl) {
      itemEl.addEventListener('mouseenter', function() {
        self.setCurrentNavItem(itemEl)
      })
    })

    let siteNavEl = document.getElementsByClassName('site-nav')[0]
    setupScrollWatching(siteNavEl, this)

    this.emitter.on("searching-active", isActive => {
      this.searchIsActive = isActive;
      if(isActive) {
        this.clearCurrentNavItem()
      }
    });

  },

  data() {
    return {
      navItemEls: [],
      navYOffset: 10,
      navXOffset: 10,
      currentNavItem: null,
    }
  },

  methods: {

    setNavOffsets() {
      function getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left + window.scrollX,
          right: rect.right + window.scrollX,
          top: rect.top + window.scrollY
        };
      }

      this.navYOffset = Array.from(this.navItemEls)[0].closest('.nav-container').offsetHeight - 1
      this.navXOffset = window.innerWidth - getOffset(Array.from(this.navItemEls)[0].closest('.nav-container')).right

      // console.log(`Y Offset: ${this.navYOffset}`)
      // console.log(`X Offset: ${this.navXOffset}`)
    },

    setCurrentNavItem(itemEl) {
      this.clearCurrentNavItem()
      this.currentNavItem = itemEl
      itemEl.classList.add('hovered')
      document.body.classList.add('showOverlayNav');
    },

    clearCurrentNavItem() {
      this.currentNavItem = null
      Array.from(this.navItemEls).forEach(function(itemEl) {
        itemEl.classList.remove('hovered')
      });
      document.body.classList.remove('showOverlayNav');
    }

  },

  computed: {
    splitPhotos: function() {
      return this.widePhotos.split(',')
    },

    photosRemaining: function() {
      return this.total - this.photos.length
    }
  },

}
</script>
